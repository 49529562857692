// SHZT 0 提交未审核/已提交  1 未提交  2 审核通过  3 审核不通过
const commonMethods = {
    // 0 可查看  1 可编辑  2 可审核
    isPower(type) {
        let powerList = localStorage.monitoringPower.split(',');
        return powerList.indexOf(type) > -1;
    },
    // 显示新建按钮 1
    showNewBtn() {
        let inPower = this.isPower('1');
        return inPower;
    },
    // 显示导出按钮
    showExportBtn() {
        let inPower = this.isPower('3');
        return inPower;
    },
    // 显示查看按钮
    showSeeBtn() {
        let inPower = this.isPower('0');
        return inPower;
        // return !this.showEditBtn(row);
    },
    // 显示编辑按钮
    showEditBtn(row) {
        let inPower = this.isPower('1');
        if ((Number(row.SHZT) === 1 || Number(row.SHZT) === 3) && inPower) {
            return true;
        } else {
            return false;
        }
    },
    // 显示审核按钮
    showCheckBtn(row) {
        let inPower = this.isPower('2');
        if (row.SHZT !== '' && Number(row.SHZT) === 0 && inPower) {
            return true;
        } else {
            return false;
        }
    },
    // 显示删除按钮  只有超级管理员可以删除 f69d2068-9767-472f-8bb2-a04fa3d8055f
    showDelBtn() {
        let inPower = this.isPower('1') && localStorage.roleId.split(",").includes(window.ROLE_ID.CJGLY);
        return inPower;
    },
    // 显示添加子节点的按钮（遗产要素清单）第三级以后不能再添加
    showAddBtn(row) {
        let inPower = this.isPower('1');
        if (Number(row.SHZT) === 2 && String(row.YCYSBM).length <= 21 && inPower) {
            return true;
        } else {
            return false;
        }
    },
    // 显示版本变更的按钮
    showRefreshBtn(row) {
        let inPower = this.isPower('1');
        if (Number(row.SHZT) === 2 && inPower) {
            return true;
        } else {
            return false;
        }
    },
    // 编辑页面的按钮
    // 保存
    showSubmit() {
        let inPower = this.isPower('1');
        return inPower;
    },
    // 审核
    showPass() {
        let inPower = this.isPower('2');
        return inPower;
    },
    // 对接按钮
    showConnect(row, vm) {
        let state = false
        // 气象 大气质量 气象灾害
        let list = ['8020101', '8020202', '8020512']
        const ItemID = vm.$route.params.ItemID;
        if (list.includes(ItemID)) {
            if (row.SFYDJ != 1) {
                state = true
            } else
                state = false
            return state;
        }

        if (row.SHZT === window.SHZT.AUDIT_PASSED && row.SFYDJ != 1) {
            state = true
        }
        return state;
    },
    // 1205 是否显示转为异常按钮
    showAbnormal(row) {
        // 未转化为异常  
        // 审核通过的  
        // 具有审核权限的 
        // 数据来源于小程序的 LRFS 2 移动端 4 小程序
        if (row.SFZYC == '0' && Number(row.SHZT) == 2 && this.isPower('2') && row.LRFS == "4") {
            return true;
        } else {
            return false;
        }
    },
    // 1205 回复按钮
    showReply() {
        let inPower = this.isPower('2');
        return inPower;
    },
    // 1201 异常转预警
    showWarnBtn(row) {
        // 未转化为异常  审核通过的  具有审核权限的
        // SFZYJ 0 未转化 1 已转化 row.SFZYJ == 0 &&
        if (row.SFZYJ == 0 && row.YCSFJC == '否' && this.isPower('2')) {
            return true;
        } else {
            return false;
        }
    },
};
export { commonMethods };