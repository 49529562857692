<template>
    <div id="basic-table" class="mainbox monitoring_table">
        <sn-bread :itemName="itemName" :breadlist="breadlist"></sn-bread>
        <div class="tableBox">
            <sn-table-group
                ref="tableGroup"
                :tableGroupAttributes="tableGroupAttributes"
                :commonMethods.sync="commonMethods"
                :class="{ showRightTable: currentItem === '1501' }"
                @handleChange="handleChange"
                @getTableData="getTableData"
            >
            </sn-table-group>
            <sn-dialog :dialogData.sync="dialogData">
                <div class="table sn_tablebody">
                    <sn-table
                        :snTable.sync="fileData"
                        @handleChange="handleChange"
                    ></sn-table>
                </div>
            </sn-dialog>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import monitoringdata from "@router/modules/remote_sense";
import { commonData } from "./table_config/table_group/sn-table-group-common";
import { commonMethods } from "./table_config/table-common-methods";
import connect_zpt_no from "./table_config/connect_zpt_no"; // 不需要对接总平台的项
import sh_btn_no from "./table_config/sh_btn_no"; // 不需要审核按钮的项

export default {
    components: {},
    name: "application-text",
    beforeRouteLeave(to, from, next) {
        if (to.name === "default") {
            next({ name: "home" });
        } else {
            next();
        }
    },
    props: {
        itemName: {
            type: String,
            default: function() {
                return "";
            },
        },
        currentChild: {
            default: function() {
                return {};
            },
        },
    },
    data() {
        return {
            monitoringdata, // 组件挂载时候根据 路由配置，生成 ItemID和 录入页面要跳转路由映射的对象
            condition: [],
            conditionList: `[]`,
            tableId: "",
            initial_tabletop: null,
            commonData,
            commonMethods,
            tableGroupAttributes: {},

            currentItem: null,
            currentMC: "",
            total: 20, // 每页的条数
            keyword: "",
            status: "",
            multipleSelection: [],
            sort: "",
            order: "",
            start: "",
            end: "",
            dialogData: {
                title: "附件列表",
                dialog: false,
            },
            dialogDisease: {
                title: "监测数据",
                dialog: false,
                row: "",
                class: "disease_history_data",
                width: "1000px",
            },
            breadlist: [
                {
                    path: "",
                    name: "工作台面",
                },
            ],
            searchData: [
                { type: "CNSXLYLX" },
                { type: "XZPG" },
                { type: "YCYSBM" },
                { type: "YEAR2" },
            ],
            fileData: {
                table: {
                    data: [],
                    showHeader: false,
                },
                columns: {
                    // 数据列
                    columnsData: [
                        {
                            prop: "SYTLJ",
                            label: "示意图",
                            width: 60,
                            showImg: true,
                        },
                        {
                            prop: "CCLJ",
                            label: "附件",
                            width: 90,
                            showFileBtn: true,
                            operateType: "dialogFileBtn",
                        },
                        {
                            prop: "WDMC",
                            label: "基础数据名称",
                        },
                    ],
                },
            },
            boxHeight: 0,
            no_shBtn_list: [], // 没有审核按钮的列表
            no_zpt_list: [], // 没有对接到总平台的按钮列表
        };
    },
    computed: {
        ...mapGetters(["heritageId", "system"]),
        routeMap: function() {
            const res = {};
            const monitoringdata = this.monitoringdata;
            if (
                monitoringdata &&
                monitoringdata.children &&
                Array.isArray(monitoringdata.children)
            ) {
                const { children } = monitoringdata;
                children.forEach((child) => {
                    child.itemId && (res[child.itemId] = child.name);
                });
            }
            return res;
        },
    },
    watch: {
        currentChild: {
            deep: true,
            immediate: true,
            handler() {
                if (Object.keys(this.currentChild).length === 0) return false;
                this.changeModule(this.currentChild);
            },
        },
    },
    created() {},
    mounted() {},
    methods: {
        ...mapActions([
            "dockData",
            "getMonitorDataList",
            "deleteMonitorData",
            "exportMonitorData",
            "getAllEnumInfobytableID",
            "recalculateDatacode",
            "GetYear",
            "ImportNatureAvgData",
        ]),
        // 各种操作
        handleChange(type, value) {
            console.log("type", type, "value", value);
            switch (type) {
                case "load": // 展开子节点(展开再去调服务)
                    this.getRowChildData(value);
                    break;
                case "buttonNew": // 新建
                    this.toNewPage("", "0", false, false);
                    break;
                case "columnEdit": // 编辑
                    this.toNewPage(value[0], "1", false, false);
                    break;
                case "columnAdd": // 增加子要素
                    this.toNewPage(value[0], "0", false, true);
                    break;
                case "columnRefresh": // 版本变更
                    this.toNewPage(value[0], "1", true, false);
                    break;
                case "columnSee": // 查看
                    this.toNewPage(value[0], "2", false, false);
                    break;
                case "columnCheck": // 审核
                    this.toNewPage(value[0], "3", false, false);
                    break;
                case "columnDelete": // 删除
                    this.deleteData(value[0].ID, this.currentItem);
                    break;
                case "buttonDel": // 批量删除
                    this.Deletesome();
                    break;
                case "buttonLoad": //下载
                    this.loadData();
                    break;
                case "buttonImport": // 导入
                    this.importData(value);
                    break;
                case "buttonExport": // 导出
                    this.exportData();
                    break;
                case "total": // 每页条数
                    this[value[1]] = value[0];
                    this.tableGroupAttributes.snPage.tiaoshu = value[0];
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case "page": // 当前是第几页
                    this.tableGroupAttributes.snPage.currentPage = value[0];
                    this.getTableData();
                    break;
                case "search": // 修改搜索条件(搜索及按钮)
                    this[value[1]] = value[0];
                    this.searchData.forEach((item) => {
                        if (item.type === value[1]) {
                            item.value = value[0];
                        }
                    });
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    this.getTableData(); // 是否需要点击查询按钮才进行搜索
                    break;
                case "searchBtn": // 点击查询按钮
                    this.getTableData();
                    break;
                case "selection": // 选择左侧复选框
                    this.selectData(value[0]);
                    break;
                case "fileBtn": // 点击表格的附件
                    this.getFileList(value);
                    break;
                case "dialogFileBtn": // 点击弹出框的附件
                    this.openFile(value);
                    break;
                case "sortable": // 排序
                    this.sortableData(value[0]);
                    break;
                case "rowClick": // 点击表格行
                    this.getRightTable(value[0]);
                    break;
                case "showNotPassReason":
                    this.showNotPassReason(value[0].SHBTGSM);
                    break;
                case "columnConnect": // 对接功能
                    this.connectToZptTest(value[0]);
                    break;
                case "textClick": // 点击表格单元格
                    this.getFileTable(value[0]);
                    break;
                default:
                    this.handleChangeSelf(type, value);
                    break;
            }
        },
        handleChangeSelf() {},
        getFileTable(data) {
            if (data.wdmc === "查看") {
                this.fileData.table.data = data.PIC;
                this.dialogData.dialog = true;
            }
        },
        // 对接到总平台
        connectToZptTest(value) {
            this.$confirm("对接到总平台, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.connectToZpt(value);
                })
                .catch(() => {
                    this.common.showMsg("已取消", "info");
                });
        },
        async connectToZpt(value) {
            let funId = this.currentItem;
            const result = await this.dockData({
                funId: funId,
                id: value.ID,
            });
            if (result.IsSuccess) {
                this.getTableData();
                this.common.showMsg("对接成功", "success");
            } else {
                this.common.showMsg("对接失败", "error");
            }
        },
        showNotPassReason(text) {
            this.$alert(text, "审核未通过原因", {
                confirmButtonText: "确定",
            });
        },
        // 获取表格数据
        async getTableData() {
            // 处理筛选，支持多项同时筛选
            let strwhere = "";
            if (this.searchData && this.searchData.length > 0) {
                this.searchData.forEach((item, index) => {
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        strwhere += '"' + item.type + "," + item.value + '",';
                    }
                });
            }
            strwhere = strwhere.slice(0, strwhere.length - 1);
            strwhere = "[" + strwhere + "]";
            // const multipleKeyword = (this.searchData || []).filter(item => (item || {}).multiple).map(ele => ele.value).join();
            // const comma = (this.keyword && multipleKeyword) ? ',' : "";
            const keyword = this.keyword;
            this.tableGroupAttributes.snTable.table.data = [];
            this.tableGroupAttributes.snTable.table.loading = true;
            // 调用监测数据
            let result = await this.getMonitorDataList({
                userid: localStorage.userId,
                itemId: this.currentItem,
                authority: false,
                keyword,
                strwhere: strwhere,
                sort: this.sort,
                sortorder: this.order,
                start: this.start,
                end: this.end,
                pageIndex: this.tableGroupAttributes.snPage.currentPage,
                pageSize: this.tableGroupAttributes.snPage.tiaoshu,
            });

            let da = result.ResultValue;
            console.log("请求回的列表数据", result);
            this.tableGroupAttributes.snTable.table.data = da.gridData;
            this.tableGroupAttributes.snPage.count = Number(da.total) || 0;
            this.tableGroupAttributes.loading = false;
            this.getDataNext();
        },
        // 配置数据
        getDataNext() {},
        setID() {
            return (
                (Math.random() * 10000000).toString(16).substr(0, 4) +
                "-" +
                new Date().getTime() +
                "-" +
                Math.random()
                    .toString()
                    .substr(2, 5)
            );
        },
        // 展开子节点(展开再去调服务)
        getRowChildData(value) {},
        // 跳转到新建或编辑页面
        toNewPage(val, type, updataVersion, addChild) {
            // type: 0 => 新建 1 => 编辑 2 => 查看
            let bm = this.$route.params.ItemID;
            let ID = val.ID;
            let version = val.BBH;
            let fid = val.ID;
            let editPage = this.routeMap[bm];
            if (editPage) {
                this.$router.push({
                    name: editPage,
                    query: {
                        val: JSON.stringify(val),
                        type: type,
                        id: addChild ? "" : ID,
                        // LB: this.$route.params.LB,
                        version: updataVersion ? version : "",
                        fid: addChild ? fid : "",
                        ItemID: bm,
                        basicType: this.$route.params.basicType,
                    },
                });
            }
        },
        // 删除前确认
        deleteData(value, itemID) {
            let checkboxlist = value;
            this.$confirm("与之相关的数据会受到影响,是否删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.delData(checkboxlist, itemID);
                })
                .catch(() => {
                    this.common.showMsg("已取消删除", "info");
                });
        },
        // 删除数据
        async delData(id, itemID) {
            let res = await this.deleteMonitorData({
                ids: id,
                itemid: this.currentItem,
            });
            if (res.IsSuccess) {
                this.common.showMsg("删除成功", "success");
                this.getTableData();
            } else {
                this.common.showMsg("删除失败", "error");
            }
        },
        // 批量删除
        Deletesome() {
            let checkboxlist = [];
            this.multipleSelection.forEach(function(o) {
                checkboxlist.push(o);
            });
            if (checkboxlist.length === 0) {
                this.common.showMsg("请选择数据项！", "warning");
            } else {
                checkboxlist = checkboxlist.join();
                this.deleteData(checkboxlist);
            }
        },
        // 点击选择框
        selectData(value) {
            this.multipleSelection = [];
            if (value && value.length > 0) {
                value.forEach((item) => {
                    this.multipleSelection.push(item.ID);
                });
            }
        },
        // 表格内容升降序排列
        sortableData(value) {
            this.order = value.order
                ? value.order.slice(0, value.order.length - 6)
                : null;
            this.sort = value.order ? value.prop : null;
            this.getTableData();
        },
        // 点击附件图标的操作
        getFileList(value) {
            let list = value[0].PIC;
            let num = list.length;
            if (num === 1) {
                // 直接点开或者下载
                this.openFile(list);
            } else {
                this.fileData.table.data = list;
                this.dialogData.dialog = true;
            }
        },
        // 下载附件
        openFile(value) {
            window.open(value[0].CCLJ);
        },
        // 导出
        exportData() {
            let url =
                window.REQUEST_URL + "MonitorData/ExportMonitorData?itemId=";
            let exportUrl = url + this.currentItem;
            $.ajax({
                headers: {
                    token: localStorage.sysAccessToken, // 此处放置请求到的用户token
                    Authorization: localStorage.sysAccessToken, // 此处放置请求到的用户token
                },
                type: "GET",
                url: exportUrl, // 请求url
                xhrFields: { responseType: "arraybuffer" },
                success: (data) => {
                    let blob = new Blob([data], {
                        type: "application/vnd.ms-excel",
                    });
                    let objectUrl = URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    a.setAttribute("style", "display:none");
                    a.setAttribute("href", objectUrl);
                    let filename = `${this.currentMC}.xls`;
                    a.setAttribute("download", filename);
                    a.click();
                    URL.revokeObjectURL(objectUrl);
                    // window.URL.revokeObjectURL(url);
                },
                error: (err) => {
                    console.log(err, "导出失败");
                },
            });
        },
        // 导入
        async importData(file) {
            const form = new FormData();
            form.append("code", this.currentItem);
            form.append("file", file.raw);
            let result = await $AXIOS({
                method: "post",
                url: `${window.REQUEST_URL}MonitorData/ImportNatureAvgData`,
                dataType: "json",
                data: form,
            });
            if (result.data.ISSUCCESS) {
                this.common.showMsg("导入成功", "success");
                this.getTableData();
            } else {
                this.common.showMsg("导入失败", "error");
            }
        },
        // 下载
        loadData() {},
        // 改变左侧项
        async changeModule(params) {
            console.log(
                "%c 🍖 params: ",
                "font-size:20px;background-color: #B03734;color:#fff;",
                params
            );
            if (!params) return;
            let name = "";
            // 特殊路由跳转
            if (name) {
                this.$router.push({ name: name });
            } else {
                // 正常表单跳转
                this.$router.push({
                    name: "remoteTable",
                    params: params,
                });
                Object.assign(this.$data, this.$options.data());

                this.currentItem = params.ItemID;
                this.currentMC = params.MC;
                if (!this.currentItem) {
                    return;
                }
                const moduleData = await import(
                    `./table_config/table_group/sn-table-group-${this.currentItem}`
                );
                let copyObj = JSON.parse(JSON.stringify(moduleData.default));
                let comObj = JSON.parse(JSON.stringify(this.commonData));
                let newObj = { ...this.commonData, ...copyObj };
                const columns = newObj.snTable.columns;
                const columnsData = columns.columnsData;
                const columnsBtn = columns.columnsBtn;
                columnsBtn.width = columns.width || 120;
                const djBtn = {
                    isShow: true,
                    showMethod: "showConnect",
                    btnType: "icon",
                    operateType: "columnConnect",
                    title: "对接",
                    icon: "el-icon-upload",
                    backColor: "transparent",
                    color: "#008c8c",
                    width: "120px",
                };
                const shBtn = {
                    isShow: true,
                    showMethod: "showCheckBtn",
                    btnType: "icon",
                    operateType: "columnCheck",
                    title: "审核",
                    icon: "el-icon-document-checked",
                    backColor: "transparent",
                    color: "#00a7bd",
                    width: "120px",
                };

                this.no_zpt_list = [];
                this.no_shBtn_list = [];
                connect_zpt_no.map((item) => {
                    this.no_zpt_list.push(item.itemID);
                });
                sh_btn_no.map((item) => {
                    this.no_shBtn_list.push(item.itemID);
                });
                // 审核按钮 是否存在
                // if (!this.no_shBtn_list.includes(String(this.currentItem))) {
                //     columnsBtn.buttonData.push(shBtn);
                // }
                // 对接按钮 是否存在
                if (!this.no_zpt_list.includes(String(this.currentItem))) {
                    columnsBtn.buttonData.push(djBtn);
                }
                this.handleDataAll(newObj, comObj);
                this.tableGroupAttributes = newObj;

                await this.addSelectByEnum(); //从枚举获取数据 添加筛选项
                // 给 tableGroupAttribute 配 遗产要素的 transList
                this.tableGroupAttributes.snPage.currentPage = 1;
                await this.getTableData();
            }
        },
        //从枚举获取数据 添加筛选项
        async addSelectByEnum() {
            const exceptItem = [101, 602, 801];
            if (exceptItem.includes(Number(this.currentItem))) {
                return;
            }
            let enums = await this.getEnums();
            // 茶特中心 种茶制茶 下拉列表 只有进程  没有部门，大系统有
            if (this.currentItem == "183" && this.system == "system1") {
                let obj = {
                    COLUMNID: "DEPARTMENTID",
                    FIENAME: "部门",
                    TABLEID: "",
                    dataItems: [
                        {
                            CODE: "08edd8ce-07e3-4aac-8bb0-e50055550577",
                            LABEL: "茶厂",
                        },
                        {
                            CODE: "1fc19f4c-d147-4e24-9558-c1853e1e8334",
                            LABEL: "村民",
                        },
                        {
                            CODE: "345c687e-ae31-491f-a038-cef9b1896a06",
                            LABEL: "茶特中心",
                        },
                    ],
                };
                enums.push(obj);
            }

            const selectData = enums.map((ele) => {
                const list = ele.dataItems.map(({ CODE, LABEL }) => ({
                    ID: CODE,
                    NAME: LABEL,
                }));

                let temp = {
                    label: ele.FIENAME,
                    clearable: true,
                    // 请选择
                    placeholder: `${ele.FIENAME}`,
                    list,
                    optionLabel: "NAME",
                    optionValue: "ID",
                    value: ele.COLUMNID,
                    operateType: "search",
                    isShow: true,
                    multiple: true,
                    width: `${
                        84 + 12 * ele.FIENAME.length < 220
                            ? 220
                            : 84 + 12 * ele.FIENAME.length
                    }px`,
                };
                return temp;
            });

            // console.log('%c 🥐 枚举列表: ', 'font-size:20px;background-color: #EA7E5C;color:#fff;', selectData);
            await this.addSelectYears(selectData); //遥感专项监测 获取时间 年
            const oldSelectData =
                (this.tableGroupAttributes.snSearch || {}).selectData || [];

            selectData.forEach((ele) => {
                const contain = oldSelectData.find(
                    (findItem) => findItem.value === ele.value
                );
                if (!contain) {
                    oldSelectData.push(ele);
                }
            });
            if (!this.tableGroupAttributes.snSearch) {
                this.tableGroupAttributes.snSearch = { selectData: [] };
            }
            this.tableGroupAttributes.snSearch.selectData = [...oldSelectData];

            // 处理searchData
            const oldSearch = this.searchData;
            const newSearch = enums.map(({ COLUMNID }) => ({
                type: COLUMNID,
                multiple: true,
            }));
            newSearch.forEach((ele) => {
                const contain = oldSearch.find(
                    (findItem) => findItem.type === ele.type
                );
                if (!contain) {
                    oldSearch.push(ele);
                }
            });
            this.searchData = [...oldSearch];
        },
        // 遥感专项监测 获取时间 年
        async addSelectYears(selectData) {
            if (Number(this.currentItem) === 90305) {
                let res = await this.GetYear();
                const list = res.Year2.map(({ YEAR2 }) => ({
                    ID: YEAR2,
                    NAME: YEAR2,
                }));
                const selectItem = {
                    label: "年份",
                    clearable: true,
                    placeholder: `请选择年份`,
                    list,
                    optionLabel: "NAME",
                    optionValue: "ID",
                    value: "YEAR2",
                    operateType: "search",
                    isShow: true,
                    multiple: true,
                    width: `180px`,
                };
                selectData.push(selectItem);
            }
        },
        async getEnums() {
            let result = [];
            let num = this.currentItem;
            const res = await this.getAllEnumInfobytableID({
                bid: num,
            });
            if (res && Array.isArray(res) && res.length > 0) {
                result = res;
            }
            return result;
        },
        // 公共的属性可以不写，提取到common中再赋值到各自的组件中
        handleDataAll(self, common) {
            Object.keys(common).forEach((key) => {
                if (self.hasOwnProperty(key)) {
                    if (typeof common[key] !== "object") return false;
                    if (
                        key === "selectData" &&
                        Array.isArray(common[key]) &&
                        common[key].length > 0
                    ) {
                        let hash = {};
                        self[key] = [...self[key], ...common[key]];
                        let newArr = self[key].reduce((item, next) => {
                            // eslint-disable-next-line
                            hash[next.label]
                                ? ""
                                : (hash[next.label] = true && item.push(next));
                            return item;
                        }, []);
                        self[key] = newArr;
                        this.searchData = [];
                        self[key].forEach((item) => {
                            let type = item.value;
                            this.searchData.push({ type: type, value: "" });
                        });
                    }
                    this.handleDataAll(self[key], common[key]);
                } else {
                    self[key] = common[key];
                }
            });
        },
        ...mapActions(["GetYcysList"]),
    },
};
</script>
<style lang="scss">
.el-breadcrumb {
    > span:nth-of-type(n + 2) {
        > span {
            font-weight: 400;
            color: #606266;
            cursor: text;
            &:hover {
                font-weight: 400;
                color: #606266;
                cursor: text;
            }
        }
    }
}
.el-icon-arrow-right {
    color: #2a63d5;
    font-weight: 700;
    font-size: 14px;
}
</style>
<style lang="scss" scoped>
.mainbox {
    // TODO
    width: 100%;
    height: 100%;
    position: relative;

    .tableBox {
        width: 100%;
        height: calc(100% - 42px);
        position: absolute;
        top: 42px;
        left: 0;
    }
    // 种茶制茶
    .zczcBtnPart {
        position: absolute;
        top: 52px;
        right: 10px;
        z-index: 10;

        .zczcBtnItem {
            width: 92px;
            height: 30px;
            color: rgba(255, 255, 255, 1);
            line-height: 30px;
            text-align: center;
            font-size: 16px;
            border-radius: 10px;
        }
        .numBtn {
            background: rgba(0, 142, 212, 1);
        }
        .tableBtn {
            background: rgba(238, 123, 81, 1);
        }
    }
    .zczcImgPart {
        width: calc(100% - 20px);
        height: calc(100% - 42px);
        position: absolute;
        top: 42px;
        left: 10px;
        z-index: 10;
        background: #fff;
    }
}
.table.sn_tablebody {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}
</style>
