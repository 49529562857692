const commonData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
            treeProps: {
                children: 'Children',
                hasChildren: 'hasChildren'
            }
        },
        columns: {
            // 选择、序号
            columnsOperate: [
                // {
                //     isShow: true,
                //     type: 'selection',
                //     width: 35
                // }, 
                {
                    isShow: true,
                    label: '序号',
                    type: 'index',
                    width: 50
                }
            ],
            // 数据列
            columnsData: [

            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snCount: {
        eachtotal: [20, 50, 100],
        defaultTiaoshu: 20
    },
    snSearch: {
        selectData: [],
        searchBtn: {
            buttonData: [{
                btnType: 'button',
                operateType: 'searchBtn',
                name: '查询',
                round: true,
                backColor: '#2a63d5',
                color: '#fff'
            }]
        }
    },
    snButton: {
        buttonData: []
    }
};
export { commonData };